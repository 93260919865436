<template>
  <v-app>
    <v-app-bar app flat>
      <v-container class="d-flex justify-center align-center">
        <v-app-bar-title>
          <v-img v-if="$vuetify.breakpoint.smAndUp" src="/images/logo_horizontal.png?v=1.0" width="195px" height="58px" contain />
          <v-img v-else src="/images/icon.png?v=1.0" height="48px" width="48px" contain />
        </v-app-bar-title>
        <v-spacer />
        <v-btn text class="mr-1">
          <v-icon left>fa-home</v-icon>Home
        </v-btn>
        <v-btn text class="mr-1" href="https://github.com/ultracast-app/sdk">
          <v-icon left>fas fa-code</v-icon>
          SDK
        </v-btn>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <div class="d-flex flex-column align-center justify-center text-center">
          <div class="logo-container">
            <v-img src="/images/icon.png?v=1.0" width="100%" height="auto" contain />
          </div>
          <h1 class="display-2 my-10">Cast Web Videos to Your TV</h1>
          <div>
            <v-btn color="primary" depressed x-large href="https://github.com/ultracast-app/sdk">
              <v-icon left>fab fa-github</v-icon>
              Get our SDK
            </v-btn>
          </div>
          <h1 class="display-2 my-10">Try our demo</h1>

          <div class="demo-container">
            <v-card>
              <v-card-title v-ripple @click="showExample = ! showExample">
                Example code
                <v-spacer />
                <v-icon v-text="showExample ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
              </v-card-title>
              <v-slide-y-transition>
                <v-card-text class="text-left" v-if="showExample" ref="exampleContent">
                  <vue-code-highlight language="javascript" :key="JSON.stringify(playerConfigs)">
                    <pre class="language-javascript">import UltraCast from 'ultracast-sdk'

const options = {{playerConfigs}};

const ultraCast = new UltraCast({
    clientKey: 'YOUR_CLIENT_KEY', //Required
    tvUUID: 'FINDED_TV_UUID', //Required
    fastForwardRewindTime: 10,

    onConnect: (ultraCast) => ultraCast.setPlayer(options),
    onPlay: () => console.log('Playing'),
    onPause: () => console.log('Paused'),
    onProgress: ( ultraCast, {time} ) => console.log('Progress', time),
    onSeek: ( ultraCast, {time} ) => console.log('Seek', time),
    onDuration: ( ultraCast, {duration} ) => console.log('Duration', duration),
    onClientDisconnect: ( ultraCast, {uuid, reason} ) => console.log('Client (TV) Disconnected', {uuid, reason}),
    onLoadedData: ( ultraCast ) => {
        console.log('Data loaded');
        //ultraCast.seek(120);
    },
    onCanPlay: ( ultraCast ) => {
        console.log('Player can play');
        //ultraCast.seek(120);
    }
});

ultraCast.connect();

/*
 * ultraCast.isConnected (bool)
 * ultraCast.currentState (played|paused)
 * ultraCast.currentTime (float)
 * ultraCast.duration (float)
 */

document.getElementById('playButton').addEventListener('click', () => {
    ultraCast.play();
});

document.getElementById('pauseButton').addEventListener('click', () => {
    ultraCast.pause();
});

document.getElementById('forwardButton').addEventListener('click', () => {
    ultraCast.forward();
});

document.getElementById('backwardButton').addEventListener('click', () => {
    ultraCast.backward();
});

document.getElementById('seekButton').addEventListener('click', () => {
    ultraCast.seek(10);
});

document.getElementById('disconnectButton').addEventListener('click', () => {
    ultraCast.disconnect();
});</pre>
                  </vue-code-highlight>
                </v-card-text>
              </v-slide-y-transition>
            </v-card>
            <v-card class="mt-2 mb-5">
              <v-card-title v-ripple @click="showHowto = ! showHowto">
                How it works?
                <v-spacer />
                <v-icon v-text="showHowto ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
              </v-card-title>
              <v-slide-y-transition>
                <v-card-text class="text-left" v-if="showHowto">
                  <ul>
                    <li>Make sure that the TV you want to use and your computer or smartphone are connected to the same network</li>
                    <li>Launch UltraCast on your TV</li>
                    <li>Click on the "Find my TV" button and select your TV from the list</li>
                    <li>Click on "Play on your TV" to start the cast of the sample video</li>
                    <li>Click on <v-icon x-small>mdi-remote-tv</v-icon> to use the integrated remote control or use your TV remote control</li>
                  </ul>
                </v-card-text>
              </v-slide-y-transition>
            </v-card>
            <v-form ref="demoForm" v-model="validForm">
              <v-text-field label="MP4 URL"
                            outlined
                            color="primary"
                            v-model="demo_model.mp4"
                            :rules="[rules.required, rules.validUrl]"
              />
              <v-text-field label="Description image URL"
                            outlined
                            color="primary"
                            v-model="demo_model.poster_image"
                            :rules="[rules.validUrl]"
              />
              <v-text-field label="Backdrop image URL"
                            outlined
                            color="primary"
                            v-model="demo_model.backdrop_image"
                            :rules="[rules.validUrl]"
              />
              <v-text-field label="Subtitles WebVTT URL"
                            outlined
                            color="primary"
                            v-model="demo_model.subtitles_url"
                            :rules="[rules.validUrl]"
              />
              <v-text-field label="Title"
                            outlined
                            color="primary"
                            v-model="demo_model.title"
              />
              <v-textarea label="Description"
                          outlined
                          color="primary"
                          v-model="demo_model.description"
                          maxlength="710"
              />
            </v-form>
            <div class="d-flex justify-center flex-column flex-md-row">
              <v-btn color="primary darken-2"
                     x-large
                     :block="$vuetify.breakpoint.smAndDown"
                     :loading="searchingDevices"
                     @click="getDevices"
                     v-if="device_model.selected === null"
              >
                <v-icon left>fa-solid fa-tv</v-icon>
                Find my TV!
              </v-btn>
              <v-btn v-else
                     color="primary darken-2"
                     x-large
                     :block="$vuetify.breakpoint.smAndDown"
                     @click="switchDevice()"
              >
                <v-icon left>fa-power-off</v-icon>
                Disconnect
              </v-btn>

              <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

              <v-btn color="primary"
                     x-large
                     :block="$vuetify.breakpoint.smAndDown"
                     :disabled="device_model.selected === null"
                     class="mt-1 mt-md-0"
                     @click="connect"
              >
                <v-icon left>fa-solid fa-play-circle</v-icon>
                Play on your TV!
              </v-btn>
              <v-btn color="info"
                     x-large
                     :disabled="device_model.selected === null || remoteStatus.duration === null"
                     class="ml-0 ml-md-1 mt-1 mt-md-0"
                     :block="$vuetify.breakpoint.smAndDown"
                     @click="showRemoteDialog = true"
              >
                <v-icon large>mdi-remote-tv</v-icon>
                <span v-if="$vuetify.breakpoint.smAndDown">Start the remote</span>
              </v-btn>
            </div>


          </div>

        </div>
      </v-container>
      <v-footer app>
        <v-container class="d-flex align-center flex-column flex-md-row justify-center">
          <div class="d-flex align-center justify-center justify-md-start mb-2 mb-md-0">
            <v-btn x-small text class="mr-1">
              <v-icon x-small left>fa-home</v-icon>Home
            </v-btn>
            <v-btn x-small text class="mr-1" href="https://github.com/ultracast-app/sdk">
              <v-icon x-small left>fas fa-code</v-icon>
              SDK
            </v-btn>
          </div>
          <v-spacer />
<!--          <small>Developed with ❤️ by FILECLOUD LTD</small>-->
        </v-container>
      </v-footer>
    </v-main>

    <v-dialog v-model="showTvsDialog" width="400px">
      <v-card>
        <v-card-title class="align-center">
          <span>Select a TV</span>
          <v-spacer />
          <v-btn icon @click="showTvsDialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-list class="py-0">
            <v-list-item v-for="(device, deviceIndex) in device_model.found"
                         :key="device.uuid + '-' + deviceIndex"
                         @click="switchDevice(device)"
            >
              <v-list-item-icon>
                <v-icon small>fa-tv</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{! device.deviceInfo.device.model ? 'Unknown' : device.deviceInfo.device.model}} {{getType(device.deviceInfo.screenSize.height)}}</v-list-item-title>
                <v-list-item-subtitle v-if="device.deviceInfo.device.manufacturer || device.version">{{device.deviceInfo.device.manufacturer}} {{device.deviceInfo.device.version}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showRemoteDialog" width="400px" persistent>
      <v-card>
        <v-card-text>
          <div class="d-flex align-center justify-center">
            <v-btn icon large @click="doRewind" :disabled="remoteStatus.duration === null">
              <v-icon>mdi-rewind-10</v-icon>
            </v-btn>
            <v-btn fab x-large color="primary" class="ma-5" @click="togglePlay" :loading="remoteStatus.duration === null">
              <v-icon v-text="remoteStatus.playing ? 'mdi-pause' : 'mdi-play'" />
            </v-btn>
            <v-btn icon large @click="doForward" :disabled="remoteStatus.duration === null">
              <v-icon>mdi-fast-forward-10</v-icon>
            </v-btn>
          </div>
          <div class="mb-3 mt-5 time-setter" v-if="remoteStatus.duration">
            <v-slider
                :max="remoteStatus.duration"
                min="0"
                step="any"
                hide-details
                thumb-label
                v-model="remoteStatus.currentTime"
                @change="doSeek"
                @start="onSliderStart"
                @end="onSliderEnd"
            >
              <template v-slot:prepend>
                {{ formattedCurrentTime }}
              </template>
              <template v-slot:append>
                {{ formattedDuration }}
              </template>
              <template v-slot:thumb-label="{value}">
                {{value.toFixed(0)}}
              </template>
            </v-slider>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text block @click="showRemoteDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show"
                :color="snackbar.color"
    >
      {{snackbar.message}}
      <template v-slot:action="{attrs}">
        <v-btn text
               v-bind="attrs"
               @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import UltraCast from 'ultracast-sdk';
import axios from 'axios';
import { component as VueCodeHighlight } from 'vue-code-highlight';
import "vue-code-highlight/themes/duotone-sea.css";
export default {
  name: 'App',
  components: {VueCodeHighlight},
  data: () => ({
    snackbar: {
      show: false,
      color: null,
      message: null
    },
    showExample: false,
    showHowto: false,
    showRemoteDialog: false,
    navShowed: false,
    rules: {
      required: v => !!v || 'This field is required',
      validUrl: v => (v === null || v.trim() === '' || !! v.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z\d@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z\d@:%_+.~#?&//=]*)/g)) || 'This isn\'t a valid URL'
    },
    validForm: null,
    demo_model: {
      mp4: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      poster_image: 'https://upload.wikimedia.org/wikipedia/commons/c/c5/Big_buck_bunny_poster_big.jpg',
      backdrop_image: null,
      subtitles_url: null,
      title: 'Big Buck Bunny',
      description: `The plot follows a day in the life of Big Buck Bunny, during which time he meets three bullying rodents: the leader, Frank the flying squirrel, and his sidekicks Rinky the red squirrel and Gimera the chinchilla. The rodents amuse themselves by harassing helpless creatures of the forest by throwing fruits, nuts, and rocks at them.

After the rodents kill two butterflies with an apple and a rock, and then attack Bunny, he sets aside his gentle nature and orchestrates a complex plan to avenge the two butterflies.

Using a variety of traps, Bunny first dispatches Rinky and Gimera. Frank, unaware of the fate of the other two, is seen taking off from a tree, and gliding towards a seemingly unsuspecting Bunny.`,
    },
    searchingDevices: false,
    showTvsDialog: false,
    device_model: {
      found: [],
      selected: null
    },
    ultraCastInstance: null,
    remoteStatus: {
      visible: false,
      playing: true,
      currentTime: null,
      duration: null,
    },
    sliderChangedStatus: false
  }),
  computed: {
    formattedDuration(){
      if ( ! this.remoteStatus.duration ) return null;
      const date = new Date(null);
      date.setSeconds(this.remoteStatus.duration); // specify value for SECONDS here
      return date.toISOString().substr(11, 8);
    },
    formattedCurrentTime(){
      if ( ! this.remoteStatus.duration ) return null;
      const date = new Date(null);
      date.setSeconds(this.remoteStatus.currentTime); // specify value for SECONDS here
      return date.toISOString().substr(11, 8);
    },
    playerConfigs(){
      let playerConfigs = {
        meta: {
          title: this.demo_model.title,
          description: this.demo_model.description,
          poster: this.demo_model.poster_image,
          backdrop: this.demo_model.backdrop_image,
        },
        source: {
          src: this.demo_model.mp4,
          type: 'video/mp4'
        }
      };

      if ( this.demo_model.subtitles_url ){
        playerConfigs.track = [
          {
            src: this.demo_model.subtitles_url,
            srclang: 'en',
            label: 'en',
            kind: 'subtitles',
            default: true
          }
        ]
      }

      return playerConfigs;
    }
  },
  watch: {
    showExample(value){
      if ( value ){
        setTimeout(() => {
          this.$vuetify.goTo( this.$refs.exampleContent )
        }, 100);
      }
    }
  },
  methods: {
    onSliderStart(){
      if ( this.remoteStatus.playing ){
        this.ultraCastInstance.pause();
        this.$set(this.remoteStatus, 'playing', false);
        this.sliderChangedStatus = true;
      }
    },
    onSliderEnd(){
      if ( this.sliderChangedStatus ){
        this.ultraCastInstance.play();
        this.$set(this.remoteStatus, 'playing', true);
        this.sliderChangedStatus = false;
      }
    },
    switchDevice(device){
      if ( this.ultraCastInstance ){
        this.ultraCastInstance.disconnect();
      }

      if ( device ){
        this.$set(this.device_model, 'selected', device);
        this.showTvsDialog = false
      } else {
        this.$set(this.device_model, 'selected', null);
      }
    },
    getType(height){
      if ( height >= 2880 ) return '5K';
      if ( height >= 2160 ) return '4K';
      if ( height >= 1440 ) return '2K';
      if ( height >= 1080 ) return 'FHD';
      if ( height >= 720 ) return 'HD';
      return null;
    },
    async getDevices(){
      this.searchingDevices = true;
      let url = '/server.php';
      if ( window.location.hostname === 'localhost' ){
        url = 'http://localhost:8000/server.php';
      }
      const response = await axios.get(url);
      if ( response.data.error === true ){
        this.$set(this.snackbar, 'color', 'error');
        this.$set(this.snackbar, 'message', 'An unexpected error has occurred');
        this.$set(this.snackbar, 'show', true);
      } else if ( response.data.clients.length <= 0 ) {
        this.$set(this.snackbar, 'color', 'warning');
        this.$set(this.snackbar, 'message', 'No TVs found');
        this.$set(this.snackbar, 'show', true);
      } else {
        this.$set(this.device_model, 'found', response.data.clients);
        this.showTvsDialog = true;
      }
      this.searchingDevices = false;
    },
    connect(){
      if ( ! this.$refs.demoForm.validate() ) return false;

      if ( this.ultraCastInstance ){
        this.ultraCastInstance.disconnect();
      }

      this.ultraCastInstance = new UltraCast({
        clientKey: '0df4a8a2-2442-4f35-859f-05d8ae989e17',
        tvUUID: this.device_model.selected.uuid,
        onConnect: (ultraCast) => {
          ultraCast.setPlayer(this.playerConfigs);
          this.showRemoteDialog = true;
        },
        onPlay: () => { console.log('onPlay'); this.$set(this.remoteStatus, 'playing', true) },
        onPlayerVisibilityChanged: ( ultraCast, {visible} ) => { console.log('onPlayerVisibilityChanged'); this.$set(this.remoteStatus, 'visible', visible) },
        onPause: () => { console.log('onPause'); this.$set(this.remoteStatus, 'playing', false) },
        onProgress: ( ultraCast, {time} ) => this.$set(this.remoteStatus, 'currentTime', time),
        onDuration: ( ultraCast, {duration} ) => this.$set(this.remoteStatus, 'duration', duration),
        onClientDisconnect: this.onClientDisconnect
      });

      this.ultraCastInstance.connect(this.device_model.selected.uuid);
    },
    onClientDisconnect(ultraCastInstance, {uuid, reason}){
      if ( uuid === this.device_model.selected.uuid ) {
        this.$set(this.snackbar, 'color', 'warning');
        this.$set(this.snackbar, 'message', 'Client disconnected (' + reason + ')');
        this.$set(this.snackbar, 'show', true);

        this.showRemoteDialog = false;
        this.switchDevice();
      }
    },
    togglePlay(){
      if ( this.remoteStatus.visible === false ){
        if ( ! this.connect() ) return false;
      }
      if ( this.remoteStatus.playing ){
        this.ultraCastInstance.pause();
        this.$set(this.remoteStatus, 'playing', false);
      } else {
        this.ultraCastInstance.play();
        this.$set(this.remoteStatus, 'playing', true);
      }
    },
    doForward(){
      if ( this.remoteStatus.visible === false ){
        if ( ! this.connect() ) return false;
      }
      console.log('doForward')
      this.ultraCastInstance.forward();
    },
    doRewind(){
      if ( this.remoteStatus.visible === false ){
        if ( ! this.connect() ) return false;
      }
      this.ultraCastInstance.backward();
    },
    doSeek(value){
      if ( this.remoteStatus.visible === false ){
        if ( ! this.connect() ) return false;
      }
      this.ultraCastInstance.seek(value);
    }
  }
}
</script>

<style lang="scss">
.logo-container{
  width: 150px;
  max-width: 80%;
}
.demo-container{
  width: 50%;

  @media screen and (max-width: 960px){
    width: 100%;
  }
}

.time-setter{
  .v-input__append-outer, .v-input__prepend-outer{
    margin-top: 8px;
  }
}

.theme--dark.v-application code{
  padding: 0 !important;
  background: none !important;
}

pre{
  padding: 0 !important;
  background: none !important;
}

.theme--dark.v-application code{
  color: #7eb6f6 !important;
}
</style>